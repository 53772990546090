<template>
  <v-container
    fluid
    class="white-background main-create-page"
    :class="[customClass]"
  >
    <div class="p-5 pb-0">
      <v-row>
        <v-col cols="8" class="pt-0" :class="headerLeftClass">
          <slot name="header-title"></slot>
        </v-col>
        <v-col cols="4" class="pt-0 text-right" :class="headerRightClass">
          <slot name="header-action"></slot>
        </v-col>
      </v-row>
    </div>
    <slot name="body"></slot>
    <slot name="barcode-setting"></slot>
    <slot name="extra"></slot>
    <template v-if="hasChanges">
      <!-- <PreventUnload :when="hasChanges" /> -->
    </template>
    <Dialog :commonDialog="routePreventDialog" :dialogWidth="600">
      <template v-slot:body>
        <v-layout>
          <v-flex md2 class="text-center">
            <!--begin::Svg Icon-->
            <inline-svg
              width="50"
              :src="$assetURL('media/custom-svg/attention-circle.svg')"
            />
            <!--end::Svg Icon-->
          </v-flex>
          <v-flex md10>
            <p class="font-weight-700">
              Are you sure you want to leave this page?
            </p>
            <v-layout>
              <v-flex>
                <v-btn
                  class="mr-2 custom-grey-border custom-bold-button white--text"
                  color="cyan"
                  small
                  v-on:click="stayOnPage"
                >
                  Stay on this page
                </v-btn>
                <v-btn
                  class="mx-2 custom-grey-border custom-bold-button"
                  small
                  v-on:click="leavePage"
                >
                  Leave this page
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </template>
    </Dialog>
  </v-container>
</template>

<script>
// import PreventUnload from "vue-prevent-unload";
import Dialog from "@/view/pages/partials/Dialog";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";

export default {
  props: {
    customClass: {
      type: String,
      default: null,
    },
    headerLeftClass: {
      type: String,
      default: null,
    },
    headerRightClass: {
      type: String,
      default: null,
    },
    routePreventDialog: {
      type: Boolean,
      default: false,
    },
    hasChanges: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    stayOnPage() {
      PreventDialogEventBus.$emit("stay-on-page", true);
    },
    leavePage() {
      PreventDialogEventBus.$emit("leave-page", true);
    },
  },
  components: {
    // PreventUnload,
    Dialog,
  },
};
</script>
